import React from 'react'
import ReactTooltip from 'react-tooltip'
import {ChapterPicture} from '..'
import {CurriculumItem} from '../../../types'
import {BookGallery} from '../book-gallery/book-gallery'
import {Link} from 'gatsby'

type Props = {
  castArrow?: boolean
  contentPadding: string
  contents: CurriculumItem
  styles: {[key: string]: string}
}

const defaultProps = {
  castArrow: false,
}

const registerLink = {
  title: `Register Now`,
  url: `/registration/`,
}

export const Block = ({
  contents,
  castArrow,
  contentPadding,
  styles,
}: Props & typeof defaultProps) => {
  const {
    caption,
    description,
    description2,
    heading,
    picture,
    stats,
    subheading,
    topics,
  } = contents

  const section = castArrow
    ? `${styles.section} ${styles.castArrow}`
    : styles.section

  if (picture === `null`) {
    return (
      <section className={section}>
        <div className={styles.wrapper}>
          <div
            className={styles.contentWrapper}
            style={{padding: contentPadding}}
          >
            <h3 className={styles.subheading}>{subheading}</h3>
            <h2 className={styles.heading}>{heading}</h2>
            <p className={styles.description}>{description}</p>
            <p className={styles.description}>{description2}</p>
            <ul className={styles.topics}>
              {topics.map(topic => (
                <li key={topic} className={styles.topic}>
                  {topic}
                </li>
              ))}
            </ul>
            <div className={styles.stats}>
              <div
                className={styles.challenges}
                data-tip="tooltip"
                data-for={`chapters-${heading}`}
              >
                <img
                  src="/images/icons/chapter.svg"
                  alt=""
                  className={styles.icon}
                />
                <strong className={styles.statsContent}>
                  {stats.chapters}
                </strong>
                <ReactTooltip
                  id={`chapters-${heading}`}
                  place="top"
                  type="dark"
                  effect="float"
                >
                  <span>Number of Chapters</span>
                </ReactTooltip>
              </div>
              <div
                className={styles.challenges}
                data-tip="tooltip"
                data-for={`challenges-${heading}`}
              >
                <img
                  src="/images/icons/box.svg"
                  alt=""
                  className={styles.icon}
                />
                <strong className={styles.statsContent}>
                  {stats.challenges}
                </strong>
                <ReactTooltip
                  id={`challenges-${heading}`}
                  place="top"
                  type="dark"
                  effect="float"
                >
                  <span>Number of Challenges</span>
                </ReactTooltip>
              </div>
              <div
                className={styles.timeRange}
                data-tip="tooltip"
                data-for={`time-range-${heading}`}
              >
                <img
                  src="/images/icons/clock.svg"
                  alt=""
                  className={styles.icon}
                />
                <strong className={styles.statsContent}>
                  {`${stats.time.min} to ${stats.time.max} hours`}
                </strong>
                <ReactTooltip
                  id={`time-range-${heading}`}
                  place="top"
                  type="dark"
                  effect="float"
                >
                  <span>Estimated Time of Completion</span>
                </ReactTooltip>
              </div>
            </div>
            <Link to={registerLink.url} className={styles.button}>
              {registerLink.title}
            </Link>
          </div>
          <div className={styles.chapterPicture}>
            <BookGallery />
          </div>
        </div>
      </section>
    )
  } else {
    // Teaser Block
    return (
      <section className={section}>
        <div className={styles.wrapper}>
          <div
            className={styles.contentWrapper}
            style={{padding: contentPadding}}
          >
            <h3 className={styles.subheading}>{subheading}</h3>
            <h2 className={styles.heading}>{heading}</h2>
            <p className={styles.description}>{description}</p>
            <p className={styles.description}>{description2}</p>
            <ul className={styles.topics}>
              {topics.map(topic => (
                <li key={topic} className={styles.topic}>
                  {topic}
                </li>
              ))}
            </ul>
            <a className={styles.buttonInactive}>Coming Soon</a>
          </div>
          <div className={styles.chapterPicture}>
            <ChapterPicture picture={picture} caption={caption} />
          </div>
        </div>
      </section>
    )
  }
}

Block.defaultProps = defaultProps
