import {motion} from 'framer-motion'
import {Link} from 'gatsby'
import React, {useState} from 'react'
import {BookSlider} from '..'
import slides from '../../../../data/book-one-slides.json'
import styles from './book-gallery.module.css'

export const BookGallery = () => {
  const [index, setIndex] = useState(0)

  const nextIndex = () => {
    setIndex(current => (current + 1) % slides.length)
  }

  const prevIndex = () => {
    setIndex(current => (current - 1 + slides.length) % slides.length)
  }

  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        <div className={styles.sliderContainer}>
          <BookSlider index={index} slides={slides} />
          <div className={styles.controls}>
            <div className={styles.buttonGroup}>
              <motion.button
                className={styles.prev}
                onClick={prevIndex}
                whileHover={{scale: 1.1}}
                whileTap={{scale: 0.9}}
              >
                <img
                  className={styles.chevron}
                  src="/images/icons/chevron-left.svg"
                  alt=""
                />
              </motion.button>
              <motion.button
                className={styles.next}
                onClick={nextIndex}
                whileHover={{scale: 1.1}}
                whileTap={{scale: 0.9}}
              >
                <img
                  className={styles.chevron}
                  src="/images/icons/chevron-right.svg"
                  alt=""
                />
              </motion.button>
            </div>
            <div className={styles.characterDescription}>
              <p
                dangerouslySetInnerHTML={{
                  __html: slides[index].description,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
