import {AnimatePresence, motion} from 'framer-motion'
import {graphql, useStaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import {CharacterItem} from '../../../../types'
import {BookPicture} from '../../book-picture/book-picture'
import styles from './book-slider.module.css'

const variants = {
  enter: {
    opacity: 0,
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: {
    zIndex: 0,
    opacity: 0,
  },
}

type Props = {
  index: number
  slides: CharacterItem[]
}

export const BookSlider = ({index, slides}: Props) => {
  const data = useStaticQuery(graphql`
    query {
      patch: file(relativePath: {eq: "curriculum-chapter-0.jpg"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      pixel: file(relativePath: {eq: "character-pixel.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      flip: file(relativePath: {eq: "character-flip.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      grandbotPop: file(relativePath: {eq: "character-grandbot-pop.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      flick: file(relativePath: {eq: "character-flick.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      malbot: file(relativePath: {eq: "character-malbot-master.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <div className={styles.container}>
      <AnimatePresence initial={false} exitBeforeEnter>
        <motion.div
          key={index}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            opacity: {duration: 0.15},
          }}
          className={styles.characterWrapper}
        >
          <BookPicture
            caption={slides[index].description}
            picture={index}
          ></BookPicture>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
/*
          <Img
            fluid={data[characters[index].name].childImageSharp.fluid}
            alt={`Meet the game character named ${characters[index].name}`}
          />
*/
