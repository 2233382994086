import {graphql, useStaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styles from './feature.module.css'

export const Feature = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "code-canvas.png"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <div className={styles.intro}>
      <div className={styles.pictureWrapper}>
        <Img
          fluid={data.file.childImageSharp.fluid}
          className={styles.picture}
        />
      </div>
      <div className={styles.contentWrapper}>
        <h3 className={styles.heading}>
          One chapter per concept.
          <br />
          50+ challenges.
          <br />
          Countless hours of play and learning.
        </h3>
        <p className={styles.description}>
          In this learn-to-code course, your kid will uncover a secret in
          a story full of fun characters and imaginative scenes. They will learn
          the fundamentals of computer programming while playing a video game
          that is free of violence, vanity, and micro-transactions.
        </p>
      </div>
    </div>
  )
}
