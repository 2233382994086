import {graphql, useStaticQuery} from 'gatsby'
import {motion} from 'framer-motion'
import Img from 'gatsby-image'
import React from 'react'
import {RemoveScroll} from 'react-remove-scroll'
import withReactContent from 'sweetalert2-react-content'
import SweetAlert from 'sweetalert2'
import styles from './chapter-picture.module.css'

const MySweetAlert = withReactContent(SweetAlert)

type Props = {
  picture: string
  caption: string
}

export const ChapterPicture = ({caption, picture}: Props) => {
  const data = useStaticQuery(graphql`
    query {
      zero: file(relativePath: {eq: "curriculum-chapter-0.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      one: file(relativePath: {eq: "curriculum-chapter-1.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      two: file(relativePath: {eq: "curriculum-chapter-2.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      three: file(relativePath: {eq: "curriculum-chapter-3.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      four: file(relativePath: {eq: "curriculum-chapter-4.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      five: file(relativePath: {eq: "curriculum-chapter-5.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      six: file(relativePath: {eq: "curriculum-chapter-6.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      seven: file(relativePath: {eq: "curriculum-chapter-7.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      eight: file(relativePath: {eq: "curriculum-chapter-8.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const handleClick = (picture: string) =>
    MySweetAlert.fire({
      footer: caption,
      showConfirmButton: false,
      html: (
        <RemoveScroll>
          <Img
            fluid={data[picture].childImageSharp.fluid}
            className={styles.picture}
          />
        </RemoveScroll>
      ),
      width: 1024,
    })

  return (
    <>
      <motion.div
        whileHover={{scale: 1.1}}
        whileTap={{scale: 0.9}}
        className={styles.pictureWrapper}
        onClick={() => handleClick(picture)}
      >
        <Img
          fluid={data[picture].childImageSharp.fluid}
          className={styles.thumb}
        />
      </motion.div>
      <p className={styles.caption}>{caption}</p>
    </>
  )
}
