import React from 'react'
import curriculum from '../../../data/curriculum.json'
import {DefaultLayout, Divider, Footer, Header} from '../../components/common'
import {CallToAction, Chapter, Feature} from '../../components/curriculum'
import styles from './index.module.css'

const logoColors = {
  primary: `#fefce1`,
  secondary: `#a25b78`,
  shadow: `transparent`,
}

const Index = () => (
  <DefaultLayout>
    <div className={styles.page}>
      <Header
        logoColors={logoColors}
        title="Our Curriculum"
        headerClass={styles.headerSection}
      >
        <Feature />
      </Header>
      <Chapter contents={curriculum[0]} castArrow side="center" />
      <Chapter contents={curriculum[3]} side="center" />
      <Divider height={12.8} />
      <CallToAction />
      <Footer />
    </div>
  </DefaultLayout>
)

Index.displayName = `CurriculumPage`

export default Index
