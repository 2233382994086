import React from 'react'
import {Block} from '..'
import {CurriculumItem} from '../../../types'
import styles from './left-block.module.css'

type Props = {
  castArrow: boolean
  contentPadding: string
  contents: CurriculumItem
}

const defaultProps = {
  castArrow: false,
  contentPadding: `8rem 1.6rem 4.8rem`,
}

export const LeftBlock = (props: Props) => <Block styles={styles} {...props} />

LeftBlock.defaultProps = defaultProps
