import React from 'react'
import {CenterBlock, LeftBlock, RightBlock} from '..'
import {CurriculumItem} from '../../../types'

type Props = {
  castArrow?: boolean
  contents: CurriculumItem
  side: string
}

const defaultProps = {
  castArrow: false,
  side: `left`,
}

export const Chapter = ({
  contents,
  side,
  castArrow,
}: Props & typeof defaultProps) => {
  if (side === `right`) {
    return (
      <RightBlock
        contents={contents}
        contentPadding="6.4rem 1.6rem 4.8rem 6.4rem"
        castArrow={castArrow}
      />
    )
  }

  if (side === `left`) {
    return (
      <LeftBlock
        contents={contents}
        contentPadding="9.6rem 4.8rem 6.4rem 1.6rem"
        castArrow={castArrow}
      />
    )
  }

  return (
    <CenterBlock
      contents={contents}
      contentPadding="9.6rem 4.8rem 6.4rem 1.6rem"
      castArrow={castArrow}
    />
  )
}

Chapter.defaultProps = defaultProps
